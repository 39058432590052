import React from "react";

import { ProgressContent, ProgressIc } from "./styles";

const Progress = () => {
  return (
    <ProgressContent>
      <ProgressIc />
    </ProgressContent>
  );
};

export default Progress;
