import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  align-items: center;
  justify-content: center;
  left: 0px;
  top: 0px;
  display: flex;
  flex-direction: column;
  background: #fff;
`;

export const LoginLogo = styled.img`
  margin-top: 0px;
  margin-bottom: 40px;
  max-width: 320px;

  @media (max-width: 1024px) {
    margin-top: 20px;
    max-width: 200px;
  }
`;

export const LoginBackground = styled.img`
  width: 100%;
  height: auto;
  position: fixed;
  align-items: center;
  justify-content: center;
  left: 0px;
  top: 0px;
  display: flex;

  @media (max-width: 1024px) {
    width: auto;
    height: 100%;
    transform: translateX(-25%);
  }
`;

export const LoginLogoImg = styled.img``;

export const Content = styled.form`
  padding: 0 20px;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  flex-direction: column;
  display: flex;
`;

export const Title = styled.span`
  width: 100%;
  height: auto;
  float: left;
  margin: 0 0 30px 0;
  color: #333;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 1px 5px 5px rgba(0,0,0,0.1);
`;

export const InputContainer = styled.div`
  background: #fff;
  height: 60px;
  border-radius: 10px;
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 15px;
  box-shadow: 1px 5px 5px rgba(0,0,0,0.1);
  border: 1px solid rgba(0,0,0,0.2);
  overflow: hidden;

  svg {
    fill: #333 !important;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 60px;
  float: left;
  background: none;
  margin: 0;
  box-shadow: none;
  border: 0;
  padding: 0 0px 0 15px;
  font-size: 16px;
  color: #333;
`;

export const PasswordViewControl = styled.a`
  opacity: ${props => (props.active ? "0.1" : "0.7")};
  transition: all 0.5s ease;

  &:hover {
  }
`;

export const Recovery = styled.a`
  flex-direction: row;
  justify-content: flex-end;
  cursor: pointer;
`;

export const RecoveryText = styled.span`
  width: 100%;
  height: auto;
  float: left;
  margin: 0;
  text-align: right;
  color: #fff;
  font-size: 12px;

  &:hover {
    text-decoration: underline;
  }
`;

export const Bold = styled.span`
  font-weight: 700;
  color: #fff;
`;

export const Submit = styled.button`
  width: 100%;
  height: 60px;
  float: left;
  border: none;
  margin: 20px 0 0 0;
  border-radius: 8px;
  background: #333;
  transition: all 0.5s ease;
  cursor: pointer;
  color: #fff;
  box-shadow: 1px 5px 5px rgba(0,0,0,0.1);

  &:hover {
    background: rgba(0,0,0,0.4);
    color: #fff;
  }
`;

export const SubmitText = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

export const LoginFooter = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #263c5b;
  padding: 30px 0;
`;

export const LoginFooterBtns = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;

export const LoginFooterBtn = styled.a`
  padding: 0 10px;
  transition: all 0.5s ease;

  &:hover {
    opacity: 0.5;
  }

  svg {
    fill: #fff;
  }
`;

export const LoginFooterText = styled.span`
  font-size: 16px;
  color: #6a80a0;
  cursor: default;

  @media (max-width: 1024px) {
    text-align: center;
    font-size: 14px;
  }
`;

export const RegisterLink = styled.a`
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  text-align: center;
  width: 100%;
  margin: 20px 0 0 0;
  float: left;
  transition: all 0.5s ease;

  &:hover {
    opacity: 0.5;
  }
`;
