import React, { useState, useEffect } from "react";
import Moment from "moment";

import api from "../../services/api";

import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Spinner from "../../components/Spinner";

import {
  ProductsContainer,
  ProductsLoading,
  ProductsList,
  ProductContainer,
  ProductInfo,
  ProductInfoTitle,
  ProductEmpty,
  ProductInfoSubTitle,
} from "./styles";

let lang = require("moment/locale/pt-br");
Moment.locale("pt-br", lang);

const Products = () => {
  const [importacoes, setimportacoes] = useState([]);
  const [inFetchProducts, setInFetchProducts] = useState(false);

  const fetchProducts = async () => {
    setInFetchProducts(true);
    setimportacoes([]);

    try {
      const response = await api.get(`/importacoes`);

      const { data } = response;
      if (!data.error) {
        setimportacoes(data);
      }

      setInFetchProducts(false);
    } catch (err) {
      setInFetchProducts(false);
    }
  };

  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProductsContainer>
      <Header title="Logs" subtitle="Veja os logs das importações realizadas" />
      <Menu />

      <ProductsList>
        {importacoes.map((importacao) => (
          <ProductContainer key={importacao.idimportacao}>
            <ProductInfo>
              <ProductInfoSubTitle>
                {Moment(importacao.ts).format("DD/MM/YYYY")}
              </ProductInfoSubTitle>
              <ProductInfoTitle>{importacao.log}</ProductInfoTitle>
              <ProductInfoSubTitle>
                <small>{Moment(importacao.ts).fromNow()}</small>
              </ProductInfoSubTitle>
            </ProductInfo>
          </ProductContainer>
        ))}

        {inFetchProducts ? (
          <ProductsLoading>
            <Spinner size={"36px"} type="green" />
          </ProductsLoading>
        ) : !importacoes.length ? (
          <ProductEmpty>Nenhuma importacao disponível</ProductEmpty>
        ) : null}
      </ProductsList>
    </ProductsContainer>
  );
};

export default Products;
