import styled from "styled-components";

export const OrderContainer = styled.div`
  padding: 0px;
  background: #fff;
  width: 100%;
  display: flex;
  float: left;
  padding: 60px 0 0px 300px;
  flex-direction: column;

  @media (max-width: 1024px) {
    padding: 60px 0 0 0px;
  }
`;

export const OrderContent = styled.div`
  flex: 1;
  background: #fff;
  flex-direction: column;
  display: flex;
  width: 100%;
  float: left;
  padding: 20px;
`;

export const OrderLoading = styled.div`
  padding: 100px;
  border-radius: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OrderEmpty = styled.div`
  background: #fff;
  height: 0px;
  width: 100%;
  color: #333;
  font-size: 12px;
  font-weight: 700;
  opacity: 0.5;
  padding: 30px;
`;

export const OrderBack = styled.span`
  float: left;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  margin-bottom: 20px;
  background: rgba(0, 0, 0, 0.05);
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  transition: all 0.5s ease;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

export const OrderInfo = styled.div`
  float: left;
  width: 100%;
  margin-bottom: 40px;

  @media (max-width: 1024px) {
    overflow-x: scroll;
  }

  table {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: none;
    width: 100%;
    padding: 0px;
    border-spacing: 0px;
    border-collapse: separate;

    td {
      padding: 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);

      @media (max-width: 1024px) {
        font-size: 12px;
      }
    }

    td:first-child {
      border-right: 1px solid rgba(0, 0, 0, 0.2);
      font-weight: 700;
      width: 400px;
    }

    tr:nth-child(odd) {
      td {
        background: rgba(0, 0, 0, 0.05);
      }
    }
  }
`;
