import React, { useState, useEffect } from "react";
import Moment from "moment";

import api from "../../services/api";

import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Spinner from "../../components/Spinner";

import { Link } from "react-router-dom";

import {
  OrdersContainer,
  OrdersLoading,
  OrdersList,
  OrderContainer,
  OrderInfo,
  OrderInfoTitle,
  OrderEmpty,
  OrderInfoSubTitle,
} from "./styles";

let lang = require("moment/locale/pt-br");
Moment.locale("pt-br", lang);

const Orders = () => {
  const [pedidos, setPedidos] = useState([]);
  const [inFetchOrders, setInFetchOrders] = useState(false);

  const fetchOrders = async () => {
    setInFetchOrders(true);
    setPedidos([]);

    try {
      const response = await api.get(`/pedidos`);

      const { data } = response;
      if (!data.error) {
        setPedidos(data);
      }

      setInFetchOrders(false);
    } catch (err) {
      setInFetchOrders(false);
    }
  };

  useEffect(() => {
    fetchOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OrdersContainer>
      <Header title="Pedidos Importados na Totvs" subtitle="Veja todos os pedidos na totvs" />
      <Menu />

      <OrdersList>
        {pedidos.map((pedido) => (
          <Link to={`/pedido/${pedido.NROPEDIDOAFV}`}>
            <OrderContainer key={pedido.NROPEDIDOAFV}>
              <OrderInfo>
                <OrderInfoSubTitle>Código Interno: {pedido.SEQEDIPEDVENDA}</OrderInfoSubTitle>
                <OrderInfoTitle>
                  Código Commerceplus: {pedido.NROPEDIDOAFV} - R$ {pedido.VLRTOTPEDIDO}
                </OrderInfoTitle>
                <OrderInfoSubTitle>
                  <small> {Moment(pedido.DTAPEDIDOAFV).format("DD/MM/YYYY")}</small>
                </OrderInfoSubTitle>
              </OrderInfo>
            </OrderContainer>
          </Link>
        ))}

        {inFetchOrders ? (
          <OrdersLoading>
            <Spinner size={"36px"} type="green" />
          </OrdersLoading>
        ) : !pedidos.length ? (
          <OrderEmpty>Nenhuma pedido disponível</OrderEmpty>
        ) : null}
      </OrdersList>
    </OrdersContainer>
  );
};

export default Orders;
