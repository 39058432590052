import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  html,
  body {
    background: #fff;
    padding: 0px;
    margin: 0px;
    width: 100%;
    float: left;
    overflow-x:  hidden !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  * {
    box-sizing: border-box;
    font-family:"Montserrat",sans-serif;
    text-decoration: none;
    outline: none;
  }

  a { color: inherit; }

.swal-overlay {
  background: rgba(0, 0, 0, 0.9);
}

.swal-modal {
  border-radius: 2px;
  background: rgba(0, 0, 0, 0);
  margin-top: -100px;
  width: 800px;

	@media (max-width: 1024px) {
		width: 100%;
		margin: 0px;
	}
}
.swal-title {
  color: rgba(255, 255, 255, 0.8);
  font-size: 28px;
  font-weight: normal;

	@media (max-width: 1024px) {
		font-size: 20px;
	}
}
.swal-title:not(:last-child) {
  margin-bottom: 0px;
}

.swal-text {
  color: rgba(255, 255, 255, 0.4);
  font-size: 12px;
  font-weight: normal;

	@media (max-width: 1024px) {
		font-size: 10px;
	}

}

.swal-button {
  padding: 20px 40px 20px 40px;
  border-radius: 0px;
  font-weight: normal;
  font-size: 12px;
  transition: all 0.5s ease;

	@media (max-width: 1024px) {
		padding: 10px;
		font-size: 10px;
	}

}

.swal-button:hover {
  opacity: 0.5;
}

.swal-footer {
  text-align: center;
}

.swal-icon--success:after,
.swal-icon--success:before,
.swal-icon--success__hide-corners {
  background: rgba(0, 0, 0, 0);
}


@keyframes modal-video {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes modal-video-inner {
	from {
		transform: translate(0, 100px);
	}

	to {
		transform: translate(0, 0);
	}
}

.modal-video {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, .5);;
	z-index: 1000000;
	cursor: pointer;
	opacity: 1;
	animation-timing-function: ease-out;
	animation-duration: .3s;
	animation-name: modal-video;
	-webkit-transition: opacity .3s ease-out;
	-moz-transition: opacity .3s ease-out;
	-ms-transition: opacity .3s ease-out;
	-o-transition: opacity .3s ease-out;
	transition: opacity .3s ease-out;
}

.modal-video-effect-exit {
	opacity: 0;

	& .modal-video-movie-wrap {
		-webkit-transform: translate(0, 100px);
		-moz-transform: translate(0, 100px);
		-ms-transform: translate(0, 100px);
		-o-transform: translate(0, 100px);
		transform: translate(0, 100px);
	}
}

.modal-video-body {
	max-width: 940px;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	display: table;
}

.modal-video-inner {
	display: table-cell;
	vertical-align: middle;
	width: 100%;
	height: 100%;
}

.modal-video-movie-wrap {
	width: 100%;
	height: 0;
	position: relative;
	padding-bottom: 56.25%;
	background-color: #333;
	animation-timing-function: ease-out;
	animation-duration: .3s;
	animation-name: modal-video-inner;
	-webkit-transform: translate(0, 0);
	-moz-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0);
	-webkit-transition: -webkit-transform .3s ease-out;
	-moz-transition: -moz-transform .3s ease-out;
	-ms-transition: -ms-transform .3s ease-out;
	-o-transition: -o-transform .3s ease-out;
	transition: transform .3s ease-out;

	& iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.modal-video-close-btn {
	position: absolute;
	z-index: 2;
	top: -35px;
	right: -35px;
	display: inline-block;
	width: 35px;
	height: 35px;
	overflow: hidden;
	border: none;
	background: transparent;

	&:before {
		transform: rotate(45deg);
	}

	&:after {
		transform: rotate(-45deg);
	}

	&:before,
	&:after {
		content: '';
		position: absolute;
		height: 2px;
		width: 100%;
		top: 50%;
		left: 0;
		margin-top: -1px;
		background: #fff;
		border-radius: 5px;
		margin-top: -6px;
	}
}

.menu-wrapper > div {
	margin-left: -105px;
}
  
`;

export default GlobalStyle;
