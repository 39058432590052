import React, { useState, useEffect } from "react";
import Moment from "moment";

import api from "../../services/api";

import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Spinner from "../../components/Spinner";

import { Link } from "react-router-dom";

import {
  BilledContainer,
  BilledLoading,
  BilledList,
  OrderContainer,
  OrderInfo,
  OrderInfoTitle,
  OrderEmpty,
  OrderInfoSubTitle,
} from "./styles";

let lang = require("moment/locale/pt-br");
Moment.locale("pt-br", lang);

const Billed = () => {
  const [faturamentos, setFaturamentos] = useState([]);
  const [inFetch, setinFetch] = useState(false);

  const fetchBilleds = async () => {
    setinFetch(true);
    setFaturamentos([]);

    try {
      const response = await api.get(`/faturamentos`);

      const { data } = response;
      if (!data.error) {
        setFaturamentos(data);
      }

      setinFetch(false);
    } catch (err) {
      setinFetch(false);
    }
  };

  useEffect(() => {
    fetchBilleds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BilledContainer>
      <Header title="Faturamentos" subtitle="Veja os status dos pedidos na Totvs" />
      <Menu />

      <BilledList>
        {faturamentos.map((faturamento) => (
          <Link to={`/faturamento/${faturamento.id}`}>
            <OrderContainer key={faturamento.id}>
              <OrderInfo>
                <OrderInfoSubTitle>
                  <small>{faturamento.dt}</small>
                </OrderInfoSubTitle>
                <OrderInfoSubTitle>PEDIDO: {faturamento.id}</OrderInfoSubTitle>
                <OrderInfoTitle bg={faturamento.status_cor}>{faturamento.status}</OrderInfoTitle>
                <OrderInfoSubTitle>
                  <small>{Moment(faturamento.ts).fromNow()}</small>
                </OrderInfoSubTitle>
              </OrderInfo>
            </OrderContainer>
          </Link>
        ))}

        {inFetch ? (
          <BilledLoading>
            <Spinner size={"36px"} type="green" />
          </BilledLoading>
        ) : !faturamentos.length ? (
          <OrderEmpty>Nenhum faturamento disponível</OrderEmpty>
        ) : null}
      </BilledList>
    </BilledContainer>
  );
};

export default Billed;
