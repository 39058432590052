import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";

import api from "../../services/api";
import qs from "qs";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as UsersActions } from "../../store/ducks/users";

import Spinner from "../../components/Spinner";

import {
  Container,
  Content,
  LoginLogo,
  Title,
  InputContainer,
  Input,
  Submit,
  SubmitText,
  PasswordViewControl
} from "./styles";

import email_ic from "../../assets/email.png";
import senha_ic from "../../assets/senha.png";
import logo from "../../assets/logo.png";
import show_password from "../../assets/show-password.png";

const Login = ({ loginUserSuccess }) => {
  const { addToast } = useToasts();

  const [securePassword, setSecurePassword] = useState(true);
  const [usuario, setUsuario] = useState("");
  const [password, setPassword] = useState("");

  const [inload, setInload] = useState("");

  const handlerLogin = async e => {
    e.preventDefault();

    setInload(true);

    try {
      const response = await api.post(
        "autenticacao",
        qs.stringify({
          usuario: usuario,
          senha: password
        })
      );

      const { data } = response;

      setInload(false);

      if (!data.error) {
        loginUserSuccess(data);
        setTimeout(() => {
          window.location = "#/home";
        }, 500);
      } else {
        addToast(data.message, {
          appearance: data.error ? "error" : "success",
          placementtype: "bottom-right",
          autoDismiss: true
        });
      }
    } catch (err) {
      setInload(false);

      addToast("Falha na Requisição", {
        appearance: "error",
        placementtype: "bottom-right",
        autoDismiss: true
      });
    }
  };

  return (
    <>
      <Container>
        <LoginLogo src={logo} alt="Vale Verde" />
        <Content onSubmit={handlerLogin}>
          <Title>SERVICE 01</Title>
          <InputContainer>
            <img src={email_ic} alt="E-mail" />

            <Input
              value={usuario}
              required={true}
              placeholder="Usuário"
              onChange={e => setUsuario(e.target.value)}
            />
          </InputContainer>
          <InputContainer>
            <img src={senha_ic} alt="Senha" />

            <Input
              value={password}
              type={securePassword ? "password" : "text"}
              required={true}
              onChange={e => setPassword(e.target.value)}
              placeholder="Senha"
            />

            <PasswordViewControl
              active={securePassword}
              onClick={() => {
                setSecurePassword(!securePassword);
              }}
            >
              <img src={show_password} alt="Mostrar/Ocultar Minha Senha" />
            </PasswordViewControl>
          </InputContainer>

          <Submit type="submit">
            {inload ? <Spinner /> : <SubmitText>ENTRAR</SubmitText>}
          </Submit>
        </Content>
      </Container>
    </>
  );
};

Login.navigationOptions = {
  title: null,
  header: null,
  tabBarVisible: false
};

export default connect(
  state => ({}),
  dispatch => bindActionCreators(UsersActions, dispatch)
)(Login);
