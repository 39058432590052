import React, { useState, useEffect } from "react";
import ReactSVG from "react-svg";

import api from "../../services/api";

import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Spinner from "../../components/Spinner";

import {
  StatusServiceContainer,
  StatusServiceContent,
  ImportFileInfo,
  ImportFileInfoDesc,
  ImportFileInfoNext,
} from "./styles";

import refresher_svg from "../../assets/refresher.svg";
import status_svg from "../../assets/order-tracking.svg";

const StatusService = () => {
  const [inFetchStatus, setInFetchStatus] = useState(1);
  const [status, setStatus] = useState(false);

  const fetchStatus = async () => {
    try {
      setInFetchStatus(1);
      const response = await api.post("/status-servico");
      const { data } = response;
      if (data) {
        const { status: _status } = data;
        setStatus(_status);
      }
      setInFetchStatus(0);
    } catch (err) {
      setStatus(0);
      setInFetchStatus(0);
    }
  };

  useEffect(() => {
    fetchStatus();
  }, []);

  return (
    <StatusServiceContainer>
      <Header title="Status do Serviço" subtitle="Veja o status da conexão com o banco de dados" />

      <Menu />

      <StatusServiceContent>
        <ImportFileInfo status={status}>
          <ReactSVG src={status_svg} />
          {!inFetchStatus ? (
            <ImportFileInfoDesc status={status}>
              {status
                ? "Estamos conectados ao banco de dados"
                : "Não estamos conectados ao banco de dados"}
            </ImportFileInfoDesc>
          ) : null}
          <ImportFileInfoNext disabled={inFetchStatus} onClick={fetchStatus}>
            {inFetchStatus ? "" : "VERIFICAR STATUS"}
            {inFetchStatus ? <Spinner /> : <ReactSVG src={refresher_svg} />}
          </ImportFileInfoNext>
        </ImportFileInfo>
      </StatusServiceContent>
    </StatusServiceContainer>
  );
};

export default StatusService;
