import styled from "styled-components";

export const MenuContent = styled.div`
  flex: 1;
  background: #f9f9f9;
  width: 300px;
  position: fixed;
  left: 0px;
  top: 0px;
  padding: 0px 0 0 0;
  height: 100%;
  display: flex;
  z-index: 1200;
  flex-direction: column;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);

  @media (max-width: 1024px) {
    padding: 60px 0 0 0;
    width: 100%;
    overflow-y: scroll;
    display: ${props => (props.active ? "flex" : "none")};
  }
`;

export const MenuLink = styled.div`
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  flex-direction: row;
  background: ${props => (props.active ? "rgba(0,0,0,0.1)" : "")};
  transition: all 0.2s ease;
  cursor: pointer;
  color: ${props => (props.active ? "#fff" : "#fff")};
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1px 0px;
  pointer-events: ${props => (props.disabled ? "none" : "all")};

  svg {
    max-width: 30px;
    max-height: 30px;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.1);
    color: #fff;
  }

  @media (max-width: 1024px) {
    flex-direction: row;

    svg {
      margin-right: 10px;
    }
  }
`;

export const MenuLinkIc = styled.div`
  width: 60px;
  height: 60px;
  background: rgba(0, 0, 0, 0.1);
  align-items: center;
  display: flex;
  margin-right: 15px;
  justify-content: center;

  svg {
    width: 36px;
    height: 36px;
    fill: #333;
  }
`;

export const MenuLinkText = styled.span`
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: #333;
`;

export const MenuNotification = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MenuNotificationCount = styled.span`
  height: 20px;
  line-height: 20px;
  width: 20px;
  float: left;
  margin: -8px 0px 0px -8px;
  background: ${props => (props.disabled ? "#5070a0" : "#e71d25")};
  border-radius: 10px;
  text-align: center;
  color: #fff;
  font-size: 12px;
  font-weight: 700;

  @media (max-width: 1024px) {
    margin: -8px 10px 0px -18px;
  }
`;

export const MenuMobileSafeArea = styled.div`
  display: none;

  @media (max-width: 1024px) {
    display: block;
  }
`;

export const MenuDesktopSafeArea = styled.div`
  display: block;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const MenuInfo = styled.div`
  background: rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const MenuInfoLogo = styled.img`
  max-width: 300px;
  padding: 40px 0;
  @media (max-width: 1024px) {
    max-width: 150px;
  }
`;
