import axios from "axios";

//export const BASE_URL = "http://127.0.0.1/service-01.commerceplus.info";
export const BASE_URL = "https://service-01.commerceplus.info/api";

const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    post: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  },
});

instance.defaults.timeout = 1200000;

export const setHashToken = (token) => {
  if (token) {
    instance.defaults.headers.common["Passport"] = token;
  } else {
    instance.defaults.headers.common["Passport"] = null;
  }
};

export const getHashToken = () => {
  return instance.defaults.headers.common["Passport"];
};

export default instance;
