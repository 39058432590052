import React, { useState } from "react";
import ReactSVG from "react-svg";
import swal from "sweetalert";
import { GrDocumentText } from "react-icons/gr";

import api from "../../services/api";

import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Spinner from "../../components/Spinner";

import {
  ImportationContainer,
  ImportationContent,
  ImportFileInfo,
  ImportFileInfoDesc,
  ImportFileInfoNext,
} from "./styles";

import next_svg from "../../assets/next2.svg";

const Importation = () => {
  const [inUpload, setInUpload] = useState(0);

  const sendData = async () => {
    setInUpload(1);

    try {
      const response = await api.get("/importar-pedidos");
      const { data } = response;
      if (data) {
        swal({
          title: data.error ? "Falha no envio!" : "Importação executada!",
          text: data.message,
          icon: data.error ? "error" : "success",
          buttons: ["Continuar"],
          dangerMode: true,
        });
      }

      setInUpload(0);
    } catch (err) {
      console.log(err);

      setInUpload(0);
      swal({
        title: "Falha no envio!",
        text: err.message,
        icon: "error",
        buttons: ["Continuar"],
        dangerMode: true,
      });
    }
  };

  return (
    <ImportationContainer>
      <Header title="Importação de Pedidos" subtitle="Importe a planilha com os produtos" />

      <Menu />

      <ImportationContent>
        <ImportFileInfo>
          <GrDocumentText size={80} color="#333" />
          <ImportFileInfoDesc>Executar importação de pedidos manualmente?</ImportFileInfoDesc>

          <ImportFileInfoNext disabled={inUpload} onClick={sendData}>
            {inUpload ? "" : "EXECUTAR IMPORTAÇÃO"}
            {inUpload ? <Spinner /> : <ReactSVG src={next_svg} />}
          </ImportFileInfoNext>
        </ImportFileInfo>
      </ImportationContent>
    </ImportationContainer>
  );
};

export default Importation;
