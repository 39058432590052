import styled from "styled-components";

export const StatusServiceContainer = styled.div`
  padding: 0px;
  background: #fff;
  width: 100%;
  display: flex;
  float: left;
  padding: 60px 0 0px 300px;
  flex-direction: column;

  @media (max-width: 1024px) {
    padding: 60px 0 0 0px;
  }
`;

export const StatusServiceContent = styled.div`
  padding: 20px;
`;

export const ImportDropZone = styled.div`
  width: 100%;
  float: left;
  height: 400px;
  border: 2px rgba(0, 0, 0, 0.2);
  border-style: dashed;

  div {
    width: 100%;
    float: left;
    height: 100%;
    background: rgba(0, 0, 0, 0.01);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  p {
    font-size: 28px;
    color: #333;
    font-weight: 700;

    @media (max-width: 1024px) {
      text-align: center;
      font-size: 14px;
    }
  }
`;

export const ImportFileInfo = styled.div`
  width: 100%;
  float: left;
  height: 400px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  svg {
    height: 100px;
    fill: #333;
  }
`;

export const ImportFileInfoDesc = styled.div`
  font-size: 22px;
  background: ${ props => props.status ? '#1da028': '#e71d25' };
  padding: 20px 40px;
  font-weight: 700;
  color: #fff;
`;

export const ImportFileInfoNext = styled.a`
  display: flex;
  flex-direction: row;
  background: #333;
  border-radius: 10px;
  margin-top: 20px;
  align-items: center;
  padding: 30px;
  color: #fff;
  font-weight: 700;
  font-size: 22px;
  transition: all 0.5s ease;
  cursor: pointer;
  justify-content: space-between;
  pointer-events: ${props => (props.disabled ? "none" : "all")};

  &:hover {
    background: #000;
  }

  svg {
    height: 32px;
    fill: #fff;
    margin-left: 20px;
  }
`;
