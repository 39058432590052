import React from "react";

import { SpinnerContent, SpinnerIc } from "./styles";

import spinner_green from "../../assets/spinner-green.png";
import spinner_white from "../../assets/spinner-white.png";

const Spinner = ({ type = "white", size = "22px" }) => {
  return (
    <SpinnerContent>
      <SpinnerIc
        size={size}
        src={type === "white" ? spinner_white : spinner_green}
        alt="Carregando..."
      />
    </SpinnerContent>
  );
};

export default Spinner;
