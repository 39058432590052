import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import Navigator from "./navigation";
import { store, persistor } from "./store";
import GlobalStyle from "./styles/global";
import { ToastProvider } from "react-toast-notifications";


const App = () => {

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GlobalStyle />
        <ToastProvider>
          <Navigator />
        </ToastProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
