import React from "react";
import ReactSVG from "react-svg";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as UsersActions } from "../../store/ducks/users";

import {
  HeaderContent,
  HeaderLink,
  HeaderRight,
  HeaderInfo,
  HeaderTitle,
  HeaderSubTitle,
  HeaderLogoAreaMenu
} from "./styles";

import exit_svg from "../../assets/exit.svg";
import menumobile_svg from "../../assets/menumobile.svg";
import close_svg from "../../assets/close.svg";

const Header = ({ logout, toggleMenu, menuMobile, subtitle, title }) => {
  return (
    <HeaderContent>
      <HeaderLogoAreaMenu onClick={toggleMenu}>
        <ReactSVG src={menuMobile ? close_svg : menumobile_svg} />
      </HeaderLogoAreaMenu>
      <HeaderInfo>
        <HeaderTitle>{title}</HeaderTitle>
        <HeaderSubTitle>{subtitle}</HeaderSubTitle>
      </HeaderInfo>
      <HeaderRight>
        <HeaderLink onClick={logout}>
          <ReactSVG src={exit_svg} />
        </HeaderLink>
      </HeaderRight>
    </HeaderContent>
  );
};

export default connect(
  state => ({
    menuMobile: state.users.menuMobile
  }),
  dispatch => bindActionCreators({ ...UsersActions }, dispatch)
)(Header);
