import styled from "styled-components";

export const HeaderContent = styled.div`
  background: #fff;
  display: flex;
  flex-direction: row;
  width: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  height: 60px;
  z-index: 1100;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  padding-left: 300px;

  @media (max-width: 1024px) {
    padding-left: 0px;
    flex-direction: row;
    z-index: 1300 !important;
  }
`;

export const HeaderLogo = styled.img`
  margin: 0 0px;
  max-height: 40px;
`;

export const HeaderLogoArea = styled.div`
  height: 60px;
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  align-items: center;
  flex: 1;

  @media (max-width: 1024px) {
    padding: 0px;
    flex: 0;
    width: 60px;
  }
`;

export const HeaderLogoAreaMenu = styled.a`
  height: 60px;
  flex-direction: row;
  align-items: center;
  padding: 0 0 0 15px;
  display: none;

  @media (max-width: 1024px) {
    display: flex;
  }

  svg {
    fill: #333;
  }
`;

export const HeaderLink = styled.a`
  cursor: pointer;
  transition: all 0.5s ease;

  &:hover {
    opacity: 0.7;
  }

  svg {
    width: 36px;
    height: 36px;
  }
`;

export const HeaderRight = styled.div`
  padding: 0 15px;
  flex-direction: row;
  display: flex;
  align-self: flex-end;
  height: 60px;
  align-items: center;

  @media (max-width: 1024px) {
    height: 60px;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 10px;
    margin-top: -120px;
  }
`;

export const HeaderInfo = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1;
  padding: 0 15px;
  justify-content: center;

  @media (max-width: 1024px) {
    padding: 0 10px;
  }
`;

export const HeaderTitle = styled.h1`
  font-size: 16px;
  color: #333;
  font-weight: 600;
  margin: 0px;

  @media (max-width: 1024px) {
  }
`;

export const HeaderSubTitle = styled.h2`
  font-size: 10px;
  color: #666;
  font-weight: 500;
  margin: 0px;

  @media (max-width: 1024px) {
    display: none;
  }
`;
