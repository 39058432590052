import React, { useState, useEffect } from "react";
import Moment from "moment";

import api from "../../services/api";

import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Spinner from "../../components/Spinner";

import {
  XmlsContainer,
  XmlsLoading,
  XmlsList,
  ProductContainer,
  ProductInfo,
  ProductInfoTitle,
  ProductEmpty,
  ProductInfoSubTitle,
} from "./styles";

let lang = require("moment/locale/pt-br");
Moment.locale("pt-br", lang);

const Xmls = () => {
  const [xmls, setXmls] = useState([]);
  const [inFetchXmls, setInFetchXmls] = useState(false);

  const fetchXmls = async () => {
    setInFetchXmls(true);
    setXmls([]);

    try {
      const response = await api.get(`/xmls`);

      const { data } = response;
      if (!data.error) {
        setXmls(data);
      }

      setInFetchXmls(false);
    } catch (err) {
      setInFetchXmls(false);
    }
  };

  useEffect(() => {
    fetchXmls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <XmlsContainer>
      <Header title="NFe" subtitle="Veja as notas fiscais sincronizadas" />
      <Menu />

      <XmlsList>
        {xmls.map((xml) => (
          <ProductContainer key={xml.arquivo}>
            <ProductInfo>
              <ProductInfoSubTitle>
                {Moment(xml.ts).format("DD/MM/YYYY HH:mm")}
              </ProductInfoSubTitle>
              <ProductInfoTitle>{xml.arquivo}</ProductInfoTitle>
              <ProductInfoSubTitle>
                <small>Sincronizada: {Moment(xml.ts).fromNow()}</small>
              </ProductInfoSubTitle>
              <a
                href={xml.url}
                target="_blank"
                without
                rel="noopener noreferrer"
              >
                <ins>VISUALIZAR</ins>
              </a>
            </ProductInfo>
          </ProductContainer>
        ))}

        {inFetchXmls ? (
          <XmlsLoading>
            <Spinner size={"36px"} type="green" />
          </XmlsLoading>
        ) : !xmls.length ? (
          <ProductEmpty>Nenhuma xml disponível</ProductEmpty>
        ) : null}
      </XmlsList>
    </XmlsContainer>
  );
};

export default Xmls;
