import styled, { keyframes } from "styled-components";

export const SpinnerContent = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const SpinnerIcAnimation = keyframes`  
  0% { transform: rotate(0deg); }
  50% { transform: rotate(360deg);  }
  100% { transform: rotate(0deg);  }
`;

export const SpinnerIc = styled.img`
  animation-name: ${SpinnerIcAnimation};
  width: ${props => props.size};
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-play-state: running;
`;
