import React from "react";
import { Link } from "react-router-dom";

import { GrStackOverflow, GrDocumentText } from "react-icons/gr";
import { GiPriceTag } from "react-icons/gi";
import { AiFillCloseCircle, AiFillDashboard } from "react-icons/ai";
import { FaThList, FaFileInvoiceDollar } from "react-icons/fa";
import { FiMonitor } from "react-icons/fi";
import { TbFileTypeXml } from "react-icons/tb";

import { withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as UsersActions } from "../../store/ducks/users";

import {
  MenuContent,
  MenuLink,
  MenuLinkIc,
  MenuLinkText,
  MenuInfo,
  MenuInfoLogo,
} from "./styles";

import menu_logo from "../../assets/logo.png";

const Menu = ({ logout, location, menuMobile, toggleMenu, userData }) => {
  const { pathname } = location;
  let page = pathname.split("/")[1];

  const handlerMenu = () => {
    toggleMenu();
  };

  return (
    <>
      <MenuContent active={menuMobile}>
        <MenuInfo>
          <MenuInfoLogo src={menu_logo} alt="Kímika" />
        </MenuInfo>

        <Link to="/importacao-de-pedidos">
          <MenuLink
            active={page === "importacao-de-pedidos"}
            onClick={handlerMenu}
          >
            <MenuLinkIc>
              <GrDocumentText size={32} color="#333" />
            </MenuLinkIc>
            <MenuLinkText>+ Importar Pedidos</MenuLinkText>
          </MenuLink>
        </Link>

        <Link to="/importacao-de-precos">
          <MenuLink
            active={page === "importacao-de-precos"}
            onClick={handlerMenu}
          >
            <MenuLinkIc>
              <GiPriceTag size={32} color="#333" />
            </MenuLinkIc>
            <MenuLinkText>+ Importar Preços</MenuLinkText>
          </MenuLink>
        </Link>

        <Link to="/importacao-de-estoques">
          <MenuLink
            active={page === "importacao-de-estoques"}
            onClick={handlerMenu}
          >
            <MenuLinkIc>
              <GrStackOverflow size={32} color="#333" />
            </MenuLinkIc>
            <MenuLinkText>+ Importar Estoques</MenuLinkText>
          </MenuLink>
        </Link>

        <Link to="/pedidos">
          <MenuLink
            active={page === "pedidos" || page === "pedido"}
            onClick={handlerMenu}
          >
            <MenuLinkIc>
              <FaThList size={32} color="#333" />
            </MenuLinkIc>
            <MenuLinkText>Pedidos</MenuLinkText>
          </MenuLink>
        </Link>

        <Link to="/nfes">
          <MenuLink active={page === "nfes"} onClick={handlerMenu}>
            <MenuLinkIc>
              <TbFileTypeXml size={32} color="#333" />
            </MenuLinkIc>
            <MenuLinkText>NFe</MenuLinkText>
          </MenuLink>
        </Link>

        <Link to="/faturamentos">
          <MenuLink active={page === "faturamentos"} onClick={handlerMenu}>
            <MenuLinkIc>
              <FaFileInvoiceDollar size={32} color="#333" />
            </MenuLinkIc>
            <MenuLinkText>Faturamentos</MenuLinkText>
          </MenuLink>
        </Link>

        <Link to="/status-servico">
          <MenuLink active={page === "status-servico"} onClick={handlerMenu}>
            <MenuLinkIc>
              <FiMonitor size={32} color="#333" />
            </MenuLinkIc>
            <MenuLinkText>Status</MenuLinkText>
          </MenuLink>
        </Link>

        <Link to="/logs">
          <MenuLink
            active={page === "logs" || page === "home" || page === ""}
            onClick={handlerMenu}
          >
            <MenuLinkIc>
              <AiFillDashboard size={32} color="#333" />
            </MenuLinkIc>
            <MenuLinkText>Logs</MenuLinkText>
          </MenuLink>
        </Link>

        <MenuLink onClick={logout}>
          <MenuLinkIc>
            <AiFillCloseCircle size={32} color="#333" />
          </MenuLinkIc>
          <MenuLinkText>Sair </MenuLinkText>
        </MenuLink>
      </MenuContent>
    </>
  );
};

const MenuContainer = connect(
  (state) => ({
    notificationsCount: state.users.notificationsCount,
    menuMobile: state.users.menuMobile,
    isMessagesVisibile: state.users.isMessagesVisibile,
    userData: state.users.data,
  }),
  (dispatch) => bindActionCreators({ ...UsersActions }, dispatch)
)(Menu);

const ShowTheLocationWithRouter = withRouter(MenuContainer);

export default ShowTheLocationWithRouter;
