import React from "react";
import { HashRouter, Route } from "react-router-dom";

import Importations from "../pages/Importations";
import StatusService from "../pages/StatusService";
import Orders from "../pages/Orders";
import Order from "../pages/Order";
import Clients from "../pages/Clients";
import Billed from "../pages/Billed";

import ImportationOrders from "../pages/ImportationOrders";
import ImportationPrices from "../pages/ImportationPrices";
import ImportationStocks from "../pages/ImportationStocks";

import Xmls from "../pages/Xmls";

export default (
  <HashRouter>
    <Route exact path="/" component={Importations} />
    <Route exact path="/home" component={Importations} />

    <Route path="/importacao-de-pedidos" component={ImportationOrders} />
    <Route path="/importacao-de-precos" component={ImportationPrices} />
    <Route path="/importacao-de-estoques" component={ImportationStocks} />

    <Route path="/pedidos" component={Orders} />
    <Route path="/pedido/:idpedido" component={Order} />
    <Route path="/status-servico" component={StatusService} />
    <Route path="/logs" component={Importations} />
    <Route path="/clientes" component={Clients} />
    <Route path="/faturamentos" component={Billed} />

    <Route path="/nfes" component={Xmls} />
  </HashRouter>
);
