import React, { useState, useEffect } from "react";
import Moment from "moment";

import api from "../../services/api";

import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Spinner from "../../components/Spinner";

import {
  ClientsContainer,
  ClientsLoading,
  ClientsList,
  ClientContainer,
  ClientInfo,
  ClientInfoTitle,
  ClientEmpty,
  ClientInfoSubTitle,
} from "./styles";

let lang = require("moment/locale/pt-br");
Moment.locale("pt-br", lang);

const Clients = () => {
  const [clientes, setClientes] = useState([]);
  const [inFetchClientes, setinFetchClientes] = useState(false);

  const fetchClients = async () => {
    setinFetchClientes(true);
    setClientes([]);

    try {
      const response = await api.get(`/clientes`);

      const { data } = response;
      if (!data.error) {
        setClientes(data);
      }

      setinFetchClientes(false);
    } catch (err) {
      setinFetchClientes(false);
    }
  };

  useEffect(() => {
    fetchClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ClientsContainer>
      <Header title="clientes na Totvs" subtitle="Veja todos os clientes na totvs" />
      <Menu />

      <ClientsList>
        {clientes.map((cliente) => (
          <ClientContainer key={cliente.SEQEDIPEDVENDACLIENTE}>
            <ClientInfo>
              <ClientInfoSubTitle>{cliente.NOMERAZAO}</ClientInfoSubTitle>
              <ClientInfoTitle>
                CPF: {cliente.NROCGCCPF}-{cliente.DIGCGCCPF}
              </ClientInfoTitle>
              <ClientInfoSubTitle>
                <small>
                  {cliente.LOGRADOURO}, {cliente.NROLOGRADOURO} - {cliente.BAIRRO} -{" "}
                  {cliente.CIDADE}/{cliente.UF} ({cliente.CEP})
                </small>
              </ClientInfoSubTitle>
            </ClientInfo>
          </ClientContainer>
        ))}

        {inFetchClientes ? (
          <ClientsLoading>
            <Spinner size={"36px"} type="green" />
          </ClientsLoading>
        ) : !clientes.length ? (
          <ClientEmpty>Nenhum cliente disponível</ClientEmpty>
        ) : null}
      </ClientsList>
    </ClientsContainer>
  );
};

export default Clients;
