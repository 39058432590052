import React, { useState, useEffect } from "react";
import Moment from "moment";

import api from "../../services/api";

import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Spinner from "../../components/Spinner";

import { Link } from "react-router-dom";

import { useParams } from "react-router-dom";

import {
  OrderContainer,
  OrderLoading,
  OrderContent,
  OrderBack,
  OrderEmpty,
  OrderInfo,
} from "./styles";

let lang = require("moment/locale/pt-br");
Moment.locale("pt-br", lang);

const Order = () => {
  const [pedido, setPedido] = useState(null);
  const [cliente, setCliente] = useState(null);
  const [itens, setItens] = useState([]);
  const [fpgs, setFpgs] = useState([]);

  const [inFetchOrder, setInFetchOrder] = useState(false);

  const { idpedido } = useParams();

  const fetchOrder = async (idpedido) => {
    setInFetchOrder(true);
    setPedido([]);

    try {
      const response = await api.get(`/pedido/${idpedido}`);

      const { data } = response;
      if (!data.error) {
        setPedido(data);
      }

      setInFetchOrder(false);
    } catch (err) {
      setInFetchOrder(false);
    }
  };

  const fetchOrderItems = async (idpedido) => {
    setInFetchOrder(true);
    setItens([]);

    try {
      const response = await api.get(`/pedido-itens/${idpedido}`);

      const { data } = response;
      if (!data.error) {
        setItens(data);
      }

      setInFetchOrder(false);
    } catch (err) {
      setInFetchOrder(false);
    }
  };

  const fetchOrderClient = async (idpedido) => {
    setInFetchOrder(true);
    setCliente(null);

    try {
      const response = await api.get(`/cliente/${idpedido}`);

      const { data } = response;
      if (!data.error) {
        setCliente(data);
      }

      setInFetchOrder(false);
    } catch (err) {
      setInFetchOrder(false);
    }
  };

  const fetchOrderFpg = async (idpedido) => {
    setInFetchOrder(true);
    setFpgs([]);

    try {
      const response = await api.get(`/pedido-fpg/${idpedido}`);

      const { data } = response;
      if (!data.error) {
        setFpgs(data);
      }

      setInFetchOrder(false);
    } catch (err) {
      setInFetchOrder(false);
    }
  };

  useEffect(() => {
    fetchOrder(idpedido);
    fetchOrderItems(idpedido);
    fetchOrderClient(idpedido);
    fetchOrderFpg(idpedido);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OrderContainer>
      <Header title={`Pedido, Código: ${idpedido}`} subtitle="Veja os detalhes do pedido" />
      <Menu />

      <OrderContent>
        <div>
          <Link to="/pedidos">
            <OrderBack>&laquo; VOLTAR AOS PEDIDOS</OrderBack>
          </Link>
        </div>

        {pedido ? (
          <OrderInfo>
            <table>
              {Object.keys(pedido).map((key) => (
                <tr>
                  <td>{key}</td>
                  <td>{pedido[key]}</td>
                </tr>
              ))}
            </table>
          </OrderInfo>
        ) : null}

        {cliente ? (
          <OrderInfo>
            <table>
              <tr>
                <td colSpan={2}>CLIENTE</td>
              </tr>
              {Object.keys(cliente).map((key) => (
                <tr>
                  <td>{key}</td>
                  <td>{cliente[key]}</td>
                </tr>
              ))}
            </table>
          </OrderInfo>
        ) : null}

        {fpgs.map((item, index) => (
          <OrderInfo>
            <table>
              <tr>
                <td colSpan={2}>FORMA DE PAGAMENTO {index + 1}</td>
              </tr>
              {Object.keys(item).map((key) => (
                <tr>
                  <td>{key}</td>
                  <td>{item[key]}</td>
                </tr>
              ))}
            </table>
          </OrderInfo>
        ))}

        {itens.map((item, index) => (
          <OrderInfo>
            <table>
              <tr>
                <td colSpan={2}>ITEM {index + 1}</td>
              </tr>
              {Object.keys(item).map((key) => (
                <tr>
                  <td>{key}</td>
                  <td>{item[key]}</td>
                </tr>
              ))}
            </table>
          </OrderInfo>
        ))}

        {inFetchOrder ? (
          <OrderLoading>
            <Spinner size={"36px"} type="green" />
          </OrderLoading>
        ) : !pedido ? (
          <OrderEmpty>Dados do pedido indisponível</OrderEmpty>
        ) : null}
      </OrderContent>
    </OrderContainer>
  );
};

export default Order;
