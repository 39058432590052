import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  loginUserRequest: ["userData"],
  loginUserSuccess: ["userData"],
  loginUserFailure: ["message"],
  logout: null,
  setNotificationsCount: ["notificationsCount"],
  toggleMenu: ["menuStatus"],
  toggleChat: ["isMessagesVisibile"],
  openChat: ["currentChat"],
  closeChat: ["currentChat"],
  closeMessages: ["closeMessages"]
});

const INITIAL_STATE = {
  isLoggedIn: false,
  data: { hash: "" },
  loading: false,
  notificationsCount: null,
  menuMobile: false,
  isMessagesVisibile: 0,
  currentChat: 0
};

const toggleChat = (state = INITIAL_STATE) => ({
  ...state,
  isMessagesVisibile: !state.isMessagesVisibile
});

const closeMessages = (state = INITIAL_STATE) => ({
  ...state,
  isMessagesVisibile: 0
});

const openChat = (state = INITIAL_STATE, action) => ({
  ...state,
  isMessagesVisibile: 1,
  currentChat: action.currentChat
});

const closeChat = (state = INITIAL_STATE, action) => ({
  ...state,
  currentChat: 0
});

const toggleMenu = (state = INITIAL_STATE) => ({
  ...state,
  menuMobile: !state.menuMobile
});

const loginRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true
});

const loginSuccess = (state = INITIAL_STATE, action) => ({
  ...state,
  data: action.userData,
  isLoggedIn: true,
  loading: false
});

const loginFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  error: action.error,
  isLoggedIn: false,
  loading: false
});

const logout = (state = INITIAL_STATE) => ({ ...state, ...INITIAL_STATE });

const setNotificationsCount = (state = INITIAL_STATE, action) => ({
  ...state,
  notificationsCount: action.notificationsCount
});

export default createReducer(INITIAL_STATE, {
  [Types.LOGIN_USER_REQUEST]: loginRequest,
  [Types.LOGIN_USER_SUCCESS]: loginSuccess,
  [Types.LOGIN_USER_FAILURE]: loginFailure,
  [Types.LOGOUT]: logout,
  [Types.SET_NOTIFICATIONS_COUNT]: setNotificationsCount,
  [Types.TOGGLE_MENU]: toggleMenu,
  [Types.TOGGLE_CHAT]: toggleChat,
  [Types.OPEN_CHAT]: openChat,
  [Types.CLOSE_CHAT]: closeChat,
  [Types.CLOSE_MESSAGES]: closeMessages
});
