import { createStore, compose, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import createSagaMiddleware from "redux-saga";

import storage from "redux-persist/lib/storage";

import reducers from "./ducks";
import sagas from "./sagas";

import { setHashToken } from "../services/api";

const middlewares = [];

const sagaMiddleware = createSagaMiddleware({});

middlewares.push(sagaMiddleware);

const composer = compose(applyMiddleware(...middlewares));

const persistConfig = {
  key: "kimika",
  storage: storage,
  blacklist: [""]
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore(persistedReducer, composer);

sagaMiddleware.run(sagas);

export const persistor = persistStore(store, {}, () => {
  const token = store.getState().users.data.hash;
  setHashToken(token);
  store.subscribe(() => {
    const token = store.getState().users.data.hash;
    setHashToken(token);
  });
});
