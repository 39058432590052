import { all, takeLatest } from "redux-saga/effects";

import { Types as UserTypes } from "../ducks/users";

import { loginUserRequest } from "./users";

export default function* rootSaga() {
  return yield all([
    takeLatest(UserTypes.LOGIN_USER_REQUEST, loginUserRequest)
  ]);
}
