import React from "react";
import { HashRouter, Route, Redirect } from "react-router-dom";

import Login from "../pages/Login";

export default (
  <HashRouter>
    <Route exact path="/" component={Login} />
    <Route exact path="/login" component={Login} />
    <Redirect path="/" component={Login} />
  </HashRouter>
);
