import styled from "styled-components";

export const ClientsContainer = styled.div`
  padding: 0px;
  background: #fff;
  width: 100%;
  display: flex;
  float: left;
  padding: 60px 0 0px 300px;
  flex-direction: column;

  @media (max-width: 1024px) {
    padding: 60px 0 0 0px;
  }
`;

export const ClientsLoading = styled.div`
  padding: 100px;
  border-radius: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ClientsList = styled.div`
  flex: 1;
  background: #fff;
  flex-direction: column;
  display: flex;
`;

export const ClientsListTitle = styled.div`
  flex-direction: column;
  padding: 20px;
`;

export const ClientsListTitleText = styled.div`
  flex: 1;
  color: #10234b;
  font-size: 18px;
  font-weight: 500;
`;

export const ClientsListTitleBack = styled.div`
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

export const ClientsListTitleBackText = styled.div`
  color: #333;
  font-size: 12px;
  padding-left: 5px;
`;

export const ClientHeader = styled.div`
  background: #fff;
`;

export const ClientFooter = styled.div`
  background: #fff;
`;

export const ClientContainer = styled.div`
  background: ${(props) => (props.finished ? "#fff" : "#f5f5f5")};
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  transition: all 0.4s ease;

  &:hover {
    background: rgba(0, 0, 0, 0.1) !important;

    .opt {
      opacity: 1;
    }
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;

    .opt {
      opacity: 1 !important;
    }
  }
`;

export const ClientInfo = styled.div`
  flex-direction: column;
  flex: 1;
  padding-right: 20px;
  display: flex;
`;

export const ClientInfoTitle = styled.div`
  color: #333;
  font-size: 14px;
  font-weight: 700;
`;

export const ClientInfoSubTitle = styled.div`
  color: #333;
  font-size: 12px;
  opacity: 0.6;
  font-weight: 600;
`;

export const ClientInfoCategory = styled.div`
  color: #333;
  font-size: 10px;
  font-weight: 600;
  opacity: 0.4;
`;

export const ClientsListSprt = styled.div`
  background: #fff;
  height: 0px;
  width: 100%;
`;

export const ClientEmpty = styled.div`
  background: #fff;
  height: 0px;
  width: 100%;
  color: #333;
  font-size: 12px;
  font-weight: 700;
  opacity: 0.5;
  padding: 30px;
`;

export const ClientInfoDesc = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`;

export const ClientInfoDescItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
`;

export const ClientInfoDescItemIc = styled.div`
  svg {
    width: 22px;
    height: 22px;
    fill: #333;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ClientInfoDescItemTitle = styled.span`
  font-size: 12px;
  font-weight: 600;
  margin-left: 10px;
  height: 22px;
  line-height: 22px;
`;

export const Clientstatus = styled.span`
  color: ${(props) => (props.finished ? "#333" : "#df1418")};
  font-size: 12px;
  font-weight: 700;

  @media (max-width: 1024px) {
    margin: 20px 0;
  }
`;

export const ClientOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #333;
  border-radius: 5px;
  padding: 15px;
  margin-left: 5px;
  cursor: pointer;
  transition: all 0.5s ease;
  opacity: 0;

  @media (max-width: 1024px) {
    margin: 5px 0 0 0;
    width: 100%;
    padding: 10px;
  }

  &:hover {
    background: #333;
  }
`;

export const ClientOptionText = styled.span`
  color: #fff;
  font-size: 12px;
  font-weight: 700;
`;

export const ClientFiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.05);
  align-items: center;
  padding: 20px 0;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 20px;
  }
`;

export const ClientFilters = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  @media (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
    margin: 20px 0;
  }
`;

export const ClientFilter = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  align-items: center;

  border-left: 1px solid rgba(0, 0, 0, 0.2);

  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
    color: #333;
  }

  @media (max-width: 1024px) {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
`;

export const ClientFilterLabel = styled.div`
  font-size: 12px;
  color: #333;
  height: 20px;
  line-height: 20px;
  margin-left: 10px;
`;

export const Clientsearch = styled.form`
  display: flex;
  flex-direction: row;
  padding: 0 20px;
  justify-content: flex-end;
  flex: 1;

  @media (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
  }
`;

export const ClientsearchIptContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;

  svg {
    width: 20px;
    height: 20px;
    fill: #333;
    margin-left: 5px;
  }

  @media (max-width: 1024px) {
    margin: 0 0 20px 0;
  }
`;

export const ClientsearchIpt = styled.input`
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-weight: 700;
  color: #333;
  padding: 0 20px;
  border-radius: 5px;
  margin-left: 10px;
  width: 200px;
  height: 35px;
  line-height: 35px;

  ::-webkit-input-placeholder {
    color: #333;
    font-size: 12px;
    font-weight: 700;
    width: 100%;
  }

  @media (max-width: 1024px) {
    width: 100%;
    flex: 1;
  }
`;

export const ClientsearchSubmt = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #333;
  border-radius: 5px;
  padding: 0 10px;
  height: 36px;
  cursor: pointer;
  transition: all 0.5s ease;
  color: #fff;
  font-size: 12px;
  font-weight: 700;

  @media (max-width: 1024px) {
    margin: 0;
    width: 100%;
  }

  &:hover {
    background: #333;
  }
`;

export const ClientRefresher = styled.a`
  cursor: pointer;
  transition: all 0.5s ease;
  width: 36px;
  height: 36px;
  float: left;
  margin: 0 20px;

  &:hover {
    svg {
      transform: rotate(180deg);
      opacity: 0.7;
    }
  }

  svg {
    transition: all 0.5s ease;
    width: 36px;
    height: 36px;
    fill: #333;
  }

  @media (max-width: 1024px) {
    margin: 0 0 20px 0;
  }
`;

export const ClientPlus = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #333;
  border-radius: 5px;
  padding: 0 10px;
  height: 36px;
  margin-right: 20px;
  cursor: pointer;
  transition: all 0.5s ease;
  color: #fff;
  font-size: 12px;
  font-weight: 700;

  @media (max-width: 1024px) {
    margin: 10px 0 0 0;
    width: 100%;
  }

  &:hover {
    background: #333;
  }
`;
