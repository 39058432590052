import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import OnlineStack from "./online";

import OfflineStack from "./offline";

import { getHashToken } from "../services/api";

import Progress from "../components/Progress";

const Navigation = props => {
  const [tokenSegmented, setTokenSegmented] = useState(0);

  const { isLoggedIn } = props;

  useEffect(() => {
    setInterval(() => {
      let token = getHashToken();
      setTokenSegmented(token);
    }, 1000);
  }, []);

  if (!isLoggedIn) {
    return OfflineStack;
  }

  if (!tokenSegmented) {
    return <Progress />;
  }

  return OnlineStack;
};

Navigation.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  isLoggedIn: state.users.isLoggedIn,
  hash: state.users.data.hash
});

export default connect(mapStateToProps)(Navigation);
