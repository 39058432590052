import qs from "qs";
import { put } from "redux-saga/effects";

import api from "../../services/api";
import { Creators as UserActions } from "../ducks/users";

export function* loginUserRequest(action) {

  try {
    const response = yield api.post(
      "/autenticacao",
      qs.stringify(action.userData)
    );
    const { data } = response;
    if (!data.error) {
      yield put(UserActions.loginUserSuccess(data));
    } else {
      yield put(UserActions.loginUserFailure(data.message));
    }
  } catch (err) {
    yield put(UserActions.loginUserFailure("Erro ao efetuar o login!"));
  }
}
