import styled, { keyframes } from "styled-components";

export const ProgressContent = styled.div`
  flex: 1;
  width: 100%;
  height: 7px;
  left: 0px;
  top: 0px;
  position: fixed;
  z-index: 2000;
  background: rgba(0,0,0,0.3);
  border-bottom: 1px solid rgba(0,0,0,0.2);
  box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
`;

const ProgressIcAnimation = keyframes`  
  0% { width: 0px; }
  100% { width: 92%; }
`;

export const ProgressIc = styled.div`
  float: left;
  width: 92%;
  height: 100%;
  background: rgba(0,0,0,0.3);
  animation-name: ${ProgressIcAnimation};
  animation-duration: 10s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-play-state: running;
`;
